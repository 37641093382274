import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { BreadCrumbInterface } from "@/interfaces/base"
import BreadCrumbs from "@/resources/components/breadCrumbs"
import React from "react"
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base"
import { Details } from "@/resources/views/users/details/details"
import { Wallets } from "@/resources/views/users/details/wallets"
import { useHasPermission } from "@/utils/hooks/useHasPermission"
import { Button, ButtonLink } from "@/resources/components/buttons/button"
import { PermissionEnum } from "@/enums/permission"
import { useDeleteUserMutation } from "@/connectors/fansbay/requests/userRequests"
import { toast } from "react-toastify"
import { confirmModal } from "@/resources/components/confirm"
import { Container as ModalContainer } from "react-modal-promise"

export const UserDetails = () => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const hasPermission = useHasPermission()
  const [deleteUser] = useDeleteUserMutation()
  const navigate = useNavigate()

  const handleDelete = async () => {
    try {
      await confirmModal({
        type: "danger",
        text: t("users.details.confirm_delete"),
      })

      await deleteUser(id!).unwrap()

      navigate("/users")
      toast.success(t("users.details.deleted"))
    } catch (error) {}
  }

  return (
    <div className={"flex flex-col gap-y-8"}>
      <BreadCrumbs crumbs={breadcrumbs(id!)} />
      <div className={"flex flex-row-reverse gap-x-2"}>
        {hasPermission(PermissionEnum.USERS_STORE) && (
          <ButtonLink variant={"contained"} to={`/users/${id!}/edit`}>
            {t("users.details.edit")}
          </ButtonLink>
        )}
        {hasPermission(PermissionEnum.USERS_DESTROY) && (
          <Button
            onClick={handleDelete}
            variant={"contained"}
            className={"ripple:bg-red-600 bg-red-600"}
          >
            {t("users.details.delete")}
          </Button>
        )}
      </div>
      <Tabs
        className={"w-full rounded-md border border-gray-700 bg-gray-900 p-4"}
        defaultValue={"details"}
      >
        <TabsList className={"flex flex-row gap-x-4 text-neutral-200"}>
          <Tab
            autoFocus={false}
            slotProps={{
              root: (props) => ({
                className: props.selected ? "bg-primary-600" : "bg-gray-800",
              }),
            }}
            className={"rounded-md px-6 py-2"}
            value={"details"}
          >
            {t("labels.details")}
          </Tab>
          <Tab
            autoFocus={false}
            slotProps={{
              root: (props) => ({
                className: props.selected ? "bg-primary-600" : "bg-gray-800",
              }),
            }}
            className={"rounded-md px-6 py-2"}
            value={"wallet"}
          >
            {t("labels.wallet")}
          </Tab>
        </TabsList>
        <TabPanel value={"details"}>
          <Details />
        </TabPanel>
        <TabPanel value={"wallet"}>
          <Wallets />
        </TabPanel>
      </Tabs>
      <ModalContainer />
    </div>
  )
}

const breadcrumbs = (id: string): BreadCrumbInterface[] => [
  {
    title: "users.breadcrumbs.module",
    url: "#",
  },
  {
    title: "users.breadcrumbs.users",
    url: "/users",
  },
  {
    title: "users.breadcrumbs.details",
    url: `/users/${id}`,
  },
]
