import FansbayConnector from "@/connectors/fansbay/fansbayConnector"
import {
  GetUserResponseInterface,
  GetUsersResponseInterface,
  GetWalletsResponseInterface,
  SearchUsersResponseInterface,
  UpdateBalancePropsInterface,
  UserInterface,
  UserSearch,
  WalletInterface,
} from "@/interfaces/user"

const requests = FansbayConnector.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<GetUsersResponseInterface["data"], string>({
      query: (props) => {
        return {
          url: `/api/panel/users${props}`,
          method: "GET",
        }
      },
      transformResponse: (response: GetUsersResponseInterface) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: "User", id } as const)),
              { type: "User", id: "LIST" },
            ]
          : [{ type: "User", id: "LIST" }],
    }),
    searchUsers: builder.query<UserSearch[], string>({
      query: (query) => `/api/panel/users/search${query}`,
      transformResponse: (response: SearchUsersResponseInterface) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "User", id } as const)),
              { type: "User", id: "SEARCH" },
            ]
          : [{ type: "User", id: "SEARCH" }],
    }),
    getUser: builder.query<UserInterface, string>({
      query: (id) => `/api/panel/users/${id}`,
      transformResponse: (response: GetUserResponseInterface) => response.data,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    storeUser: builder.mutation<void, FormData>({
      query: (body) => ({
        url: "/api/panel/users",
        method: "POST",
        body,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    updateUser: builder.mutation<void, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/api/panel/users/${id}`,
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "User", id: "LIST" },
      ],
    }),
    getWallets: builder.query<WalletInterface[], string>({
      query: (id) => `/api/panel/wallets?filter[user_id]=${id}`,
      transformResponse: (response: GetWalletsResponseInterface) =>
        response.data.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "UserWallet", id } as const)),
              { type: "UserWallet", id: "LIST" },
            ]
          : [{ type: "UserWallet", id: "LIST" }],
    }),
    updateBalance: builder.mutation<void, UpdateBalancePropsInterface>({
      query: ({ id, ...body }) => ({
        url: `/api/panel/wallets/${id}/charge`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "UserWallet", id: "LIST" },
        { type: "UserWallet", id },
        { type: "User", id: "LIST" },
      ],
    }),
    deleteUser: builder.mutation<void, string | number>({
      query: (id) => ({
        url: `/api/panel/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: () => [{ type: "User", id: "LIST" }],
    }),
  }),
})

export const {
  useGetUsersQuery,
  useLazySearchUsersQuery,
  useGetUserQuery,
  useGetWalletsQuery,
  useUpdateBalanceMutation,
  useStoreUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = requests
